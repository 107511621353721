<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    label?: string;
    placeholder?: string;
    inputClass?: string;
    suffixIcon?: string;
    prefixIcon?: string;
    suffixFunction?: () => void;
    textarea?: boolean;
    type?: "text" | "password" | "number" | "email";
    color?: "gray" | "dark" | "light" | "outline";
    size?: "sm" | "base" | "lg" | "xl";
    readonly?: boolean;
    suffixSquare?: boolean;
    gradient?: boolean;
    modelValue: any;
  }>(),
  {
    type: "text",
    color: "gray",
    size: "base",
  }
);

const emit = defineEmits<{
  (e: "change", val: any): void;
  (e: "input", val: any): void;
  (e: "update:model-value", val: any): void;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:model-value", val);
  },
});

const slots = useSlots();

const hasPrefix = computed(() => !!slots.prefix);
const hasSuffix = computed(() => !!slots.suffix);

const isComponent = computed(() => (props.textarea ? "textarea" : "input"));

function onClickSuffix() {
  props.suffixFunction?.();
}
</script>

<template>
  <label
    class="v-input"
    :class="{
      gray: color === 'gray',
      light: color === 'light',
      outline: color === 'outline',
      dark: color === 'dark',
    }"
  >
    <div v-if="label" class="v-input__label">{{ label }}</div>
    <div class="v-input__wrapper" :class="{ visible: gradient }">
      <div v-if="hasPrefix || prefixIcon" class="v-input__prefix" :class="{ static: prefixIcon }">
        <slot name="prefix">
          <div type="button" class="v-input__prefix-btn">
            <FontIcon :icon="prefixIcon" size="20" />
          </div>
        </slot>
      </div>

      <input
        v-if="isComponent === 'input'"
        v-model="value"
        class="v-input__element"
        :class="[
          inputClass,
          {
            'v-input__element--xl': size === 'xl',
            'pl-0': prefixIcon,
            'w-0 flex-1 static': suffixIcon || prefixIcon,
            'v-input__element--base': size === 'base',
            'v-input__element--sm': size === 'sm',
          },
        ]"
        :type="type"
        :readonly="readonly"
        :placeholder="placeholder || label"
        autocomplete="off"
        v-bind="$attrs"
        @change="emit('change', $event)"
        @input="emit('input', $event)"
      />
      <textarea
        v-else
        v-model="value"
        class="v-input__element"
        :class="[
          inputClass,
          { 'v-input__element--xl': size === 'xl', 'w-0 flex-1 static': suffixIcon || prefixIcon, 'v-input__element--base': size === 'base', 'v-input__element--sm': size === 'sm' },
        ]"
        :type="type"
        :readonly="readonly"
        :placeholder="placeholder || label"
        v-bind="$attrs"
        @change="emit('change', $event)"
        @input="emit('input', $event)"
      />
      <span v-if="gradient" class="v-input__gradient" />
      <span v-else class="border v-input__border" />

      <div v-if="hasSuffix || suffixIcon" class="v-input__suffix" :class="{ static: suffixIcon }">
        <slot name="suffix">
          <button type="button" class="v-input__suffix-btn" @click="onClickSuffix">
            <FontIcon :icon="suffixIcon" size="20" />
          </button>
        </slot>
      </div>
    </div>
  </label>
</template>

<style lang="scss">
.v-input {
  width: 100%;
  transition: color 0.2s ease-in-out;
  position: relative;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;

    background: var(--bg_secondary__100);
    border-radius: var(--b-radius);
    overflow: hidden;

    &.visible {
      overflow: visible;
    }
  }

  &__gradient {
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    border-radius: 4px;
    background: linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%);
    opacity: 0;
    transition: 0.3s;
  }

  &.outline {
    outline: none;

    .v-input__prefix-btn {
      background: transparent;
    }

    .v-input__wrapper {
      background: transparent;
    }

    .v-input__border {
      opacity: 1;
      border-color: var(--bg_light__100);
    }
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--b-radius);
    border: 1px solid transparent;
    transition: 0.2s;
  }

  &__element {
    width: 100%;
    font-weight: 600;
    color: var(--color_main__100);
    padding: 14.5px 12px;
    font-weight: 700;
    position: relative;
    background: transparent;
    text-overflow: ellipsis;
    z-index: 1;
    transition: 0.2s;

    &:hover + .v-input__border,
    &:focus + .v-input__border {
      border-color: var(--bg_light__100);
    }

    &::placeholder {
      color: var(--color_secondary__100);
      font-weight: 600;
    }

    &--sm {
      border-radius: 4px;
      font-size: 16px;
      padding: 10.5px 12px;
      font-weight: 600;
    }

    &--xl {
      @apply px-[30px] py-[25px] font-semibold rounded-[6px];
    }
  }

  &__prefix {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    &-btn {
      &:hover {
        background: transparent;
      }
    }
  }

  &__suffix {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &__prefix,
  &__suffix {
    height: 100%;

    &.static {
      position: relative;
      aspect-ratio: 1/1;
      aspect-ratio: 1/1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-btn {
      padding: 0;
      width: 32px;
      height: 32px;
      border-radius: var(--b-radius-sm);
      background: transparent;
      color: var(--color_secondary__100);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--bg_light__100);
      }
    }
  }

  &__prefix {
    &-btn {
      width: 48px;
      height: 48px;

      &:hover {
        background: transparent;
      }
    }
  }

  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
    color: var(--color_secondary__100);
  }
}

@include dark {
  .v-input {
    &__wrapper {
      background: var(--bg_secondary__200);
    }
  }
}
</style>
